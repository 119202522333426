import React, { useState, useEffect } from 'react';
import { Modal, Spin, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import TrainingProgramItem from '../../pages/Common/Detail/UserOfflineCourseDetail/TrainingProgram';

import { GetTrainingProgram } from 'services/rocketService/TrainingProgram';

const showItem = (props) => {
  const { visible, programId, userOfflineTrainingPlan, courseItemId: groupCourseItemId, onClose, from } = props;

  const { ilsVersion } = userOfflineTrainingPlan || {};

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await GetTrainingProgram(programId);
    if (res.response.ok) {
      setData(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const trainProps = {
    ...data,
    hideTitle: true,
    ilsVersion,
    from,
    groupCourseItemId
  };

  return (
    <Modal
      title={
        !loading ? (
          <div>
            <span>{data.title}&nbsp;</span>
            {
              ilsVersion === 'V4' ? (
                <span>{data.difficulty2}</span>
              ) : (
                <span>{data.difficulty}</span>
              )
            }
          </div>
        ) : (
          <Skeleton.Input style={{ width: 120 }} active={true} />
        )
      }
      width={850}
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        {JSON.stringify(data) !== '{}' ? (
          <InfiniteScroll dataLength={5} height={650}>
            <TrainingProgramItem {...trainProps} />
          </InfiniteScroll>
        ) : (
          <div style={{ height: '650px' }}></div>
        )}
      </Spin>
    </Modal>
  );
};

export default showItem;
