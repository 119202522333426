
import { List, Spin, Empty, Button, Timeline, Image } from 'antd';
import moment from 'moment';
import { useIntl, getLocale } from 'umi'
import style from './index.less';

const ClockInRecord = ({ dataSource, loadData, isLoading, from = 'other' }) => {
    const intl = useIntl()
    const language = getLocale() === 'zh-CN' ? 'zh' : 'en'

    const getItemValue = (itemsItem) => {
        switch (itemsItem.type) {
            case "Number":
                return <span>{itemsItem.value}</span>
            case "Boolean":
                return <span>{intl.formatMessage({ id: itemsItem.value == 'true' ? 'yes' : 'no' })}</span>;
                break;
            case "TimePicker":
                return <span>{itemsItem.value && moment(itemsItem.value).isValid() ? `${moment(itemsItem.value).format('YYYY/MM/DD HH:mm')}` : ''}</span>
                break;
            case "ImagePicker":
                const imageData = itemsItem.value ? JSON.parse(itemsItem.value) : null
                return imageData && Array.isArray(imageData) && imageData.length > 0 ?
                    <div style={{ marginBottom: '10px' }}>
                        {
                            imageData.map((item, index) => {
                                return (
                                    <Image
                                        key={`${index}${itemsItem.programFeedbackPropertyId}`}
                                        width={100}
                                        height={100}
                                        style={{ objectFit: 'cover' }}
                                        src={`${item}`} />
                                )
                            })
                        }
                    </div>
                    :
                    <span></span>
                break;
            case "VideoPicker":
                const videoList = itemsItem.value ? JSON.parse(itemsItem.value) : null
                return (
                    <div>
                        {
                            videoList && Array.isArray(videoList) && videoList.length > 0 ?
                                videoList.map((item, index) => {
                                    return <video
                                        height={300}
                                        src={item}
                                        key={index}
                                        controls={true}
                                    />
                                })
                                :
                                null
                        }
                    </div>
                )
            case "Duration":
                return <span>{itemsItem.value ? itemsItem.value : ''}</span>
            case "Selection":
            case "Checkbox":
                // const value = itemsItem.programFeedbackProperty && itemsItem.programFeedbackProperty.extraProperties && itemsItem.programFeedbackProperty.extraProperties.options;
                // return <span>
                //     {
                //         Array.isArray(value) ?
                //             value.map(item => (item.value == itemsItem.value) && item.text)
                //             :
                //             itemsItem.value
                //     }
                // </span>
                if (itemsItem.programFeedbackProperty && itemsItem.programFeedbackProperty.extraProperties) {
                    const { options } = itemsItem.programFeedbackProperty.extraProperties[language] ?? itemsItem.programFeedbackProperty.extraProperties;
                    return <span>
                        {
                            Array.isArray(options) ?
                                options.map(item => (item.value == itemsItem.value) && item.text)
                                :
                                itemsItem.value
                        }
                    </span>
                }               
            case "Rating":
                // const option = itemsItem.programFeedbackProperty && itemsItem.programFeedbackProperty.extraProperties && itemsItem.programFeedbackProperty.extraProperties.showText;
                // const text = option && Array.isArray(option) && option[itemsItem.value - 1];
                // return <span>
                //     {text || itemsItem.value}
                // </span>
                if (itemsItem.programFeedbackProperty && itemsItem.programFeedbackProperty.extraProperties) {
                    const { showText } = itemsItem.programFeedbackProperty.extraProperties[language] ?? itemsItem.programFeedbackProperty.extraProperties;
                    const text = showText && Array.isArray(showText) && showText[itemsItem.value - 1];
                    return <span>
                        {text || itemsItem.value}
                    </span>
                }
            default:
                return <span>{`${itemsItem.value}`}</span>;
        }
    }

    const getItemName = (itemsItem) => {
        switch (itemsItem.type) {
            default:
                return <b>{`${from === 'other' ? itemsItem.displayName : itemsItem.programFeedbackProperty.displayName}：`}</b>;
        }
    }

    const showItem = (itemsItem) => {
        return <div>
            {getItemName(itemsItem)}
            {getItemValue(itemsItem)}
        </div>
    }

    return (
        <div className={style.adminClockInRecord}>
            <Spin
                spinning={isLoading}
            >
                {
                    dataSource && dataSource.items && dataSource.items.length > 0 ?
                        <Timeline
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={dataSource.items}
                                renderItem={item => (
                                    <Timeline.Item
                                        className={style.outList}
                                    >
                                        <div className={style.time}>{moment(item.creationTime).local().format('MM/DD HH:mm')}</div>
                                        <div>
                                            {item.num && <b>{intl.formatMessage({ id: 'whichSession' }, { num: item.num })} &nbsp;&nbsp;{item?.subjectName} </b>}
                                            {item.iLs && <b>{item.iLs.code}</b>}
                                        </div>
                                        <div><b>{item.trainingProgram && item.trainingProgram.title}</b> </div>
                                        {
                                            Array.isArray(item.feedbackProperties) && item.feedbackProperties.length > 0 &&
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={item.feedbackProperties}
                                                renderItem={itemsItem => (<div key={`${itemsItem.programFeedbackPropertyId}`}>{showItem(itemsItem)}</div>)}
                                            />
                                        }
                                    </Timeline.Item>
                                )}
                            />
                            <div className={style.addMore}>
                                {
                                    dataSource.totalCount && dataSource.totalCount > dataSource.items.length ?
                                        <Button onClick={() => { loadData() }}>{intl.formatMessage({ id: 'loadMore' })}</Button>
                                        :
                                        <p>{intl.formatMessage({ id: 'bottomLine' })}</p>
                                }
                            </div>
                        </Timeline>
                        :
                        <Empty
                            description={intl.formatMessage({ id: 'emptyData' })}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                }
            </Spin>
        </div>
    )
}

export default ClockInRecord;
